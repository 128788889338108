<template>
    <b-field :label="input_label">
        <multiselect v-bind="$attrs" v-model="innerValue" placeholder="" :selectLabel="$tc('labels.pick')" :searchable="true" :close-on-select="false" :multiple="true" :hide-selected="true" :deselect-label="$tc('labels.remove')">
            <span slot="noResult">{{ $tc('error.no_result') }}</span>
        </multiselect>
    </b-field>
	
</template>

<script>
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'

export default {
	name: 'SelectMultiple',
	components: {
        Multiselect
	},
    props: {
        input_label: {
            type: String,
            default: ''
        },
        value: {
			type: null
		}
    },
	data: () => ({
		innerValue: ''
	}),
	watch: {
		innerValue(newVal) {
			this.$emit('input', newVal)
		},
		value(newVal) {
			this.innerValue = newVal
		}
	},
	created() {
		if (this.value) {
			this.innerValue = this.value
		}
	}
}
</script>
